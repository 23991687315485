// @flow
export const NavigationModel = [
    { 'header': 'Products & Services', 'linkTo': '/roster' },
    { 'header': 'Get Involved', 'linkTo': '/getInvolved' },
    { 'header': 'About', 'linkTo': '/about' },
    { 'header': 'News', 'linkTo': '/news' },

];

export const PageTitles = {
    'Home': 'Dashboard',
}

export const WorkItemType = {
    "NewStandard": 1,
    "Revision": 2,
    "Reapproval": 3,
    "Withdrawal": 4,
    "Reinstatement": 5,
    "ReapprovalWithEditorialChange": 6,
    "WithdrawalWithReplacement": 7
}
export const nameFormatTypeEnum = {
    'FL': 1,
    'LF': 2,
    'LFM': 3,
    'FML': 4
}

export const MainCommitteePublicPageUrl = `${process.env.REACT_APP_PUB_URL}/get-involved/technical-committees/committee`;
export const SubCommitteePublicPageUrl = "https://www.astm.org/COMMIT/SUBCOMMIT/";

export const DocTypeEnum = {
    'Minutes': 'Minutes',
    'Agenda': 'Agenda'
}

export const BucketEnum = {
    'IN': 'in',
    'OUT': 'out'
}

export const AddressValidationStatusEnum = {
    EXACT_MATCH: 'EXACT_MATCH',
    CLEANSED: 'CLEANSED',
    ERROR: 'ERROR'
}

export const MinuteAgendaFolderEnum = {
    'minutes': 'minutes',
    'agendas': 'agendas'
}

export const MinutesAndAgendasDaysEnum =
{
    agendas: 45,
    minutes: 90
}

// TODO : get domain prefix to set cookies doamin wise.
export const getDoaminPrefix = () => {
    const hostname = window.location.hostname;
    let prefixName = '';
    if (hostname) {
        let envPrefix = hostname.indexOf('-') > -1 ? hostname.split('-') : '';
        if (envPrefix && envPrefix.length > 0) {
            prefixName = `${envPrefix[0].toUpperCase()}`;
            if (prefixName == 'QA') {
                prefixName = 'TEST'
            }
            if (['DEV', 'TEST', 'STAGE'].includes(prefixName)) {
                prefixName = prefixName + '-';
            }
        }
    }
    return prefixName;
}

export const cookieKeysEnum = {
    '_MyASTM': '_MyASTM',
    'APP_SESSIONID': `${getDoaminPrefix()}MCS-APP-SESSION-ID`,
    'ILSCookie': 'MCS-ILS-COOKIE',
    'IS-OKTA-VERIFIED': `${getDoaminPrefix()}IOV`,
    'ACCOUNT-ID': 'MCS-ACCOUNT-ID',
    'REDIRECT-URL': `${getDoaminPrefix()}REDIRECT-URL`,
    'APP-DATA': `${getDoaminPrefix()}APP-DATA`,
    'BANNER-DISPLAY': `${getDoaminPrefix()}BANNER-DISPLAY`
}

export const invitationFormType = {
    Student: 1,
    Professor: 2,
    colleague: 3
}

export const LOGIN_USER_MODEL = {
    MEMBER_LOGIN: 'MEMBER-LOGIN',
    VERIFY_INFO: 'VERIFY-INFO'
}

export const ILSCartId = 'aaaa1111';

export const pageLinkEnum = [
    {
        label: "All",
        value: 0,
        link: process.env.REACT_APP_CATALOG_SEARCH
    },
    {
        label: "Meetings & Symposia",
        value: 1,
        link: process.env.REACT_APP_MEETING_SYMPOSIA_SEARCH
    },
    {
        label: "Standardization News",
        value: 2,
        link: process.env.REACT_APP_STANDARDIZATION_NEWS_SEARCH
    }, {
        label: "News Releases",
        value: 3,
        link: process.env.REACT_APP_NEWS_RELEASES_SEARCH
    },
    {
        label: "AllWithWCMS",
        value: 0,
        link: process.env.REACT_APP_WCMS_CATALOG_SEARCH
    },
]

export const informatioanlStudent="INFORMATIONAL";
export const participationalStudent="PARTICIPATING";