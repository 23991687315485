// responsive setting
export const maxWidthValue = {
    tabletOrMobile: 1199,
    mobile: 480
}

export const APP_ROOT_CONTENT_STYLE = {
    minHeight: '400px'
}

export const minWidthValue = {
    tabletOrDesktop: 481,
    desktop: 1200
}

// inactivity report url
export const inActivityURL = process.env.REACT_APP_In_Activity_URL

export const memberStatusName = {
    active: "active",
    historical: "historical"
}

export const membershipOrderType = {
    member: "member",
    renew: "renew",
    reinstate: "reinstate",
    upgrade: "upgrade"
}

export const membershipTypeJSON = {
    honorary: {
        id: 1,
        externalKey: "honorary",
        displayName: "Honorary Member"
    },
    perpetualInstitution: {
        id: 2,
        externalKey: "perpetualInstitution",
        displayName: "Perpetual Institution Member"
    },
    organizational: {
        id: 3,
        externalKey: "organizational",
        displayName: "Organizational Member"
    },
    affilate: {
        id: 4,
        externalKey: "affilate",
        displayName: "Affilate Member"
    },
    memberInstitution: {
        id: 5,
        externalKey: "memberInstitution",
        displayName: "Institution Member"
    },
    participating: {
        id: 6,
        externalKey: "participating",
        displayName: "Participating Member"
    },
    informational: {
        id: 7,
        externalKey: "informational",
        displayName: "Informational Member"
    },
    exemptMember: {
        id: 8,
        externalKey: "exemptMember",
        displayName: "Exempt Member"
    },
    seniorMember: {
        id: 9,
        externalKey: "senior",
        displayName: "Senior Member"
    },
    lifeMember: {
        id: 10,
        externalKey: "lifeMember",
        displayName: "Life Member"
    },
    perpetualIndustry: {
        id: 11,
        externalKey: "perpetualIndustry",
        displayName: "Perpetual Industry Member"
    },
    temporary: {
        id: 12,
        externalKey: "temporary",
        displayName: "Temporary Member"
    },
    isoJoint: {
        id: 13,
        externalKey: "isoJoint",
        displayName: "ISO/JOINT Member"
    },
    represntative: {
        id: 14,
        externalKey: "represntative",
        displayName: "Represntative Member"
    },
    newMember: {
        id: 15,
        externalKey: "newMember",
        displayName: "New Member"
    },
    cooperativeAgreement: {
        id: 16,
        externalKey: "cooperativeAgreement",
        displayName: "Cooperative Agreement Member"
    },
    student: {
        id: 17,
        externalKey: "student",
        displayName: "Student Member",
        displayNameCap: "STUDENT MEMBER"
    },
    mouMember: {
        id: 18,
        externalKey: "mouMember",
        displayName: "MOU Member"
    },
    GovtRepresentative: {
        id: 19,
        externalKey: "GovtRepresentative",
        displayName: "Govt Representative"
    },
    CooperativeAgreementRep: {
        id: 20,
        externalKey: "CooperativeAgreementRep",
        displayName: "Cooperative Agreement Representative"
    }
}

export const primaryActivityKey = {
    manufacturer: 1,
    salesDistributor: 2,
    userPurchaser: 3,
    testingProduct: 4,
    consultant: 5,
    governmentAgency: 6,
    academia: 7,
    consumer: 8,
    consumerAdvocacyGroup: 9,
    other: 10,
    consultingFirm: 11
}

export const membershipTypeId = {
    honorary: 1,
    perpetualInstitution: 2,
    organizational: 3,
    affilate: 4,
    memberInstitution: 5,
    participating: 6,
    informational: 7,
    exemptMember: 8,
    seniorMember: 9,
    lifeMember: 10,
    perpetualIndustry: 11,
    temporary: 12,
    isoJoint: 13,
    represntative: 14,
    newMember: 15,
    cooperativeAgreement: 16,
    studentMember: 17,
    mouMember: 18
}

export const membershipTypeName = [
    'Honorary Member',
    'Perpetual Institution Member',
    'Organizational Member',
    'Affiliate Member',
    'Member Institution',
    'Participating Member',
    'Informational Member',
    'Exempt Member',
    'Senior Member',
    'Life Member',
    'Perpetual Industry Member',
    'Temporary Member',
    'ISO/JOINT Member',
    'Representative Member',
    'New Member',
    // 'GOVT REP', This is out of scope from ASTM2.0 (MEM-12190)
    'Cooperative Agreement Member',
    'Student Member',
    'MOU Member'
]

export const historicalMonthCheck = 36;

export const degreeSoughtOptions = [
    { id: 1, value: "Certificate Program", text: "Certificate Program" },
    { id: 2, value: "Associates Degree", text: "Associates Degree" },
    { id: 3, value: "Bachelors Degree", text: "Bachelors Degree" },
    { id: 4, value: "Masters Degree", text: "Masters Degree" },
    { id: 5, value: "Doctorate Degree", text: "Doctorate Degree" }
];

export const studentMembershipSourceOptions = [
    { id: 1, value: "ASTM Website", text: "ASTM Website" },
    { id: 2, value: "ASTM Event", text: "ASTM Event" },
    { id: 3, value: "ASTM Committee/Student Member", text: "ASTM Committee/Student Member" },
    { id: 4, value: "University Faculty", text: "University Faculty" },
    { id: 5, value: "Industry Event", text: "Industry Event" },
    { id: 6, value: "Guest Lecturer", text: "Guest Lecturer" },
    { id: 7, value: "LinkedIn", text: "LinkedIn" },
    { id: 8, value: "Other", text: "Other" }
];

// Classification information primary activities list
export const activitywiseFieldName = [
    {
        primaryActivityIds: ['1', '2', '3', '4'],
        fieldName: 'manufacturer'
    },
    {
        primaryActivityIds: ['6'],
        fieldName: 'governmentagency'
    },
    {
        primaryActivityIds: ['7'],
        fieldName: 'academia'
    },
    {
        primaryActivityIds: ['5'],
        fieldName: 'consultant'
    },
    {
        primaryActivityIds: ['8'],
        fieldName: 'consumer'
    },
    {
        primaryActivityIds: ['9'],
        fieldName: 'consumeradvocacy'
    },
    {
        primaryActivityIds: ['10'],
        fieldName: 'other'
    }
];

export const HTTP_CODE = {
    CODE_200: 200,
    CODE_204: 204,
    CODE_400: 400,
    CODE_401: 401,
    CODE_500: 500,
    CODE_403: 403,
    CODE_404: 404,
    CODE_405: 405
}

export const isSearchingServer = false; // false - if you want to search locally, true - if you want to search on server

export const defaultLengthOfVisibleOptions = 2; // By default visible options in multi-select

export const countryCodesObj = {
    "US": {
        code: "US",
        name: "United States"
    },
    "CA": {
        code: "CA",
        name: "Canada"
    }
}

// Blanck value
export const blankValue = '';

// UI date format
export const uiDateFormat = 'MM/DD/YYYY';

export const extractAplhaNumericRegex = /[^0-9A-Z]+/gi

export const extractNumericRegex = /[^0-9]+/gi

export const numRegex = /^[0-9+\(\)#\.\,\s\/-]+$/

export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const zipCodeRegex = /^[a-z0-9]{0,9}$/i

export const zipCodeUSRegex = /^[0-9]{5}$/

export const zipCodeCARegex = /^[a-z]\d[a-z][ ]\d[a-z]\d$/i

export const Name_Regex = /^[A-Za-z]*$/

export const testEmailRegex = /^auto_mem_.*@yopmail\.com$/i

export const ValidationMessage = {
    adminPasswordIncorrect: "Password is incorrect",
    emailOrIdRequired: "Either Member Account Number or Email is required.",
    ConsultingFirmName: 'Name of your Consulting Firm is required.',
    OrganizationName: 'Organization Name is required.',
    ProductDescription: 'Products/services is required.',
    ConsultingOrgName: 'Name of the Organization is required.',
    ConsultingProductSells: 'Produces/sells is required.',
    ConsultingBusinessActivity: 'Business activities is required.',
    ConsumerAdvocacyName: 'Consumer Advocacy Group is required.',
    AdvocacyEmail: 'Email is required.',
    AdvocacyDescription: 'This field is required.',
    GovAgencyName: 'Government Agency Name is required.',
    GovCommitteeInterest: 'This field is required.',
    AceUniversityName: 'University/College/Institution Name is required.',
    AceInterestNature: 'This field is required.',
    OtherAffilation: 'Affiliation is required.',
    OtherInterestNature: 'This field is required.',
    InvalidEmail: 'Enter valid Email.',
    InvalidZipCode: 'Enter valid Zip/Postal Code.',
    UniversityName: 'Name of your College/University is required.',
    UniversityAddress1: 'Address Line 1 is required.',
    UniversityCity: ' City is required.',
    UniversityCountry: 'Country is required.',
    UniversityZipCode: 'Zip/Postal Code is required.',
    UniversityState: 'University state is required.',
    GraduationMonth: 'Graduation month is required.',
    GraduationYear: 'Graduation year is required.',
    FirstName: 'First Name is required.',
    LastName: 'Last Name is required.',
    City: 'City is required.',
    ZipCode: 'Zip/Postal Code is required.',
    Phone: 'Phone is required.',
    Email: 'Email is required.',
    Country: 'Country is required.',
    State: 'State is required.',
    Address1: 'Address Line 1 is required.',
    ConsultingService: 'Consulting Service is required.',
    UserName: 'User Name is required.',
    Password: 'Password is required.',
    ConfirmPassword: 'Confirm Password is required.',
    PasswordDoNotMatch: 'Password and Confirm Password should be same.',
    NumbersOnly: 'Enter valid phone number.',
    AffiliationOrganization: 'This field is required.',
    ConsumerAdvocacyGroup: 'This field is required.',
    ConsultingYourDescription: 'This field is required.',
    VolumeFormat: 'Volume Format is required.',
    OrganizationConsultingRetained: 'This field is required.',
    RequiredField: 'Required Field.',
    NickNameRequired: 'Nickname is required.',
    MemberUnAuthorized: 'Member is not authorized.',
    DocumentNotFound: 'Document not found.',
    DocumentExpired: 'Document link has expired.',
    MemberUnAuthorizedMessage: 'You are not authorized to access this document. Please contact technical contact for help.',
    IncorrectURLMessage: 'URL is incorrect. Please contact technical contact for help.'
};

export const checkDoubleQoutes = (str) => {
    if (str.charAt(0) === '"' && str.charAt(str.length - 1) === '"')
        str = str.slice(1, -1);
    return str;
}

export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

export const formLabelConstants = {
    websiteAddress: "Website address of agency",
    BranchOfficeDivision: "Branch/Office/Division",
    governmentAgencyName: 'Government Agency Name',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    affiliation: 'What is your affiliation?',
    universityCollegeInstitutionName: 'University/College/Institution Name',
    department: 'Department',
    universityCollegeInstitutionWebsite: 'University/College/Institution Website',

    //Common activity
    activity_common: {
        FacilityOrganization: 'Organization Name',
        Codivision: 'Division Name',
        ParentOrganization: 'Parent Organization',
        OrganizationDescription: 'Describe your products or services',
        Website: 'Organization Website',
    },

    //Consultant
    activity_5: {
        "FacilityOrganization": 'Name of your Consulting Firm',
        "ConsultingYourDescription": 'Clarify what best describes your consulting services',
        "ConsultingYourDescription_1": "A consultant retained by an organization, whereby the arrangement includes representing it on an ASTM committee or subcommittee.",
        "NameOfOrganization__ConsultingYourDescription_1": 'Name of the Organization you represent',
        "Website__ConsultingYourDescription_1": 'Organization Website',
        "Primarilyconsultant__ConsultingYourDescription_1": 'Does the Organization you represent primarily produce or sell products, materials, systems or services within the scope of the selected Committee?',
        "BusinessActivity__ConsultingYourDescription_1__Primarilyconsultant_1": 'What does the Organization(s) you represent produce/sell?',
        "BusinessActivity__ConsultingYourDescription_1__Primarilyconsultant_2": 'Describe the business activities of the Organizations you represent',

        "ConsultingYourDescription_2": "A consultant retained by multiple organizations, engaged in the same business activity or different business activities.",
        "Primarilyconsultant__ConsultingYourDescription_2": 'Do any of the organizations you represent primarily produce or sell products, materials, systems or services within the scope of the selected Committee?',
        "BusinessActivity__ConsultingYourDescription_2__Primarilyconsultant_1": 'What does the Organization(s) you represent produce/sell?',
        "BusinessActivity__ConsultingYourDescription_2__Primarilyconsultant_2": 'Describe the business activities of the Organizations you represent',
        "NameOfOrganization__ConsultingYourDescription_2": 'Name of the Organizations you are representing'
    },

    //Govt agency
    activity_6: {
        FacilityOrganization: 'Government Agency Name',
        Codivision: "Branch/Office/Division",
        Website: 'Website address of agency',
        OrganizationDescription: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    },

    //Academia
    activity_7: {
        FacilityOrganization: 'University/College/Institution Name',
        Codivision: 'Department',
        Website: 'University/College/Institution Website',
        OrganizationDescription: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    },

    // Consumer
    activity_8: {
        "ConsumerAdvocacyGroup": "Do you belong to a Consumer Advocacy Group or Groups?",
        "FacilityOrganization": "Name of Consumer Advocacy Group",
        "AdvocacyEmail": "Advocacy Group Contact Email",
        "OrganizationDescription": "What is the nature of your Consumer interest in the standards developed by the Committees you seek to join?"
    },

    //Other
    activity_10: {
        OtherText: 'What is your affiliation?',
        OrganizationDescription: 'What is the nature of your interest in the standards developed by the Committees you seek to join?'
    }
};

export const academiaActivityConstants = {
    universityCollegeInstitutionName: 'University/College/Institution Name',
    department: 'Department',
    universityCollegeInstitutionWebsite: 'University/College/Institution Website',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    clickHereToType: 'Click here to type',
    enterUrlHere: 'Enter URL here'
}

export const otherActivityConstants = {
    affiliation: 'What is your affiliation?',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    clickHereToType: 'Click here to type'
}

export const formComponentConstants = {
    content: "Applicants shall clearly document their relevance to the committee or subcommittee based on whether they represent interests that produce, use, regulate, or procure a material, product, system, or service covered by the scope of the committee or subcommittee.",
    placeholder: "Indicate the relevance of your products/services to the committee",
    clickHereToType: 'Click here to type'
}

export const governmentAgencyActivityConstants = {
    websiteAddress: "Website address of agency",
    branchOfficeDivision: "Branch/Office/Division",
    governmentAgencyName: 'Government Agency Name',
    clickHereToType: 'Click here to type',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?'
}

export const DownloadDocumentSource = {
    DAMCO: 'd',
    EMAIL: 'e',
    DMS: 'dms'
}

export const DownloadDocumentAccessType = {
    ANONYMOUS: '10',
    RESTRICTED: '11'
}

export const AddressValidationFormScreens = {
    MemberForm: 'MemberForm',
    CompanyForm: 'CompanyForm'
}

export const IN_BOUND_BUCKET = 'ballot-inbound-files'
export const BALLOTITEM_DOCUMENT_CATEGORY = '7'

export const IDENTITY_DELEGATION_FLAG = 'IdentityDelegation';

export const USER_INFO = 'user-info';

export const MembershipType = {
    Student: "STUDENT MEMBER"
}

export const PaidStatus = {
    PAID: 'P',
    NOT_PAID: 'N'
}

export const CONST_ZERO_VALUE = 0;

export default {
    blankValue,
    uiDateFormat,
    numRegex,
    emailRegex,
    ValidationMessage,
    checkDoubleQoutes,
    deepCopy,
    formLabelConstants,
    academiaActivityConstants,
    otherActivityConstants,
    formComponentConstants,
    governmentAgencyActivityConstants,
    DownloadDocumentSource,
    DownloadDocumentAccessType,
    AddressValidationFormScreens,
    IN_BOUND_BUCKET,
    BALLOTITEM_DOCUMENT_CATEGORY,
    IDENTITY_DELEGATION_FLAG,
    USER_INFO,
    MembershipType,
    PaidStatus
}